import { useMemo } from "react";

import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";

import { useSite } from "~/contexts";
import { countries } from "~/lib/countries";
import { useRequestInfo } from "~/lib/utils/request-info";

import { Button } from "./ui/button";
import { Combobox } from "./ui/combobox";
import { CommandGroup, CommandItem, CommandSeparator } from "./ui/command";

export const CoutrySelector = () => {
  const { country: requestCountry, preferredCountry } = useRequestInfo();
  const { country, setCountry } = useSite();
  const list = useMemo(() => {
    return countries.reduce(
      (
        countriesList: { label: string; value: string }[],
        [label, value, , isAvailable],
      ) => {
        if (!isAvailable) return countriesList;

        return [...countriesList, { label, value }];
      },
      [],
    );
  }, []);
  return (
    <div className="relative">
      <Combobox
        scrollAreaClassName="w-[350px]"
        trigger={({ isOpen }) => (
          <Button variant="linkInline">
            {preferredCountry?.countryCode || country.countryCode}{" "}
            {isOpen ? (
              <CaretUpIcon className="block h-6 w-6 " aria-hidden="true" />
            ) : (
              <CaretDownIcon className="block h-6 w-6 " aria-hidden="true" />
            )}
          </Button>
        )}
        suggestions={list}
        onSelect={value => setCountry(value)}
        defaultValue={preferredCountry?.countryCode}
      >
        {props => {
          return (
            requestCountry?.countryCode !== preferredCountry?.countryCode &&
            requestCountry && (
              <CommandGroup>
                <CommandItem
                  value={requestCountry?.countryCode}
                  onSelect={() => props.setValue(requestCountry?.countryCode)}
                >
                  {requestCountry?.name}{" "}
                  <span className="text-gray-500">(suggested)</span>
                </CommandItem>
                <CommandSeparator />
              </CommandGroup>
            )
          );
        }}
      </Combobox>
    </div>
  );
};
