import type { Entry, ResolvedAssetLink } from "contentful";
import type { ReactElement } from "react";

import { Link } from "@remix-run/react";
import type { SerializeFrom } from "@remix-run/server-runtime";

import {
  BuildingOffice2Icon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import type {
  TypeFooterSkeleton,
  TypeLink,
  TypeSimplifiedFooterSkeleton,
} from "~/contentful/compiled";
import { RichTextRender } from "~/contentful/components/rich-text";
import { useURL } from "~/contexts";
import { cn } from "~/lib/ui";

import { CoutrySelector } from "../country-selector";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import Image from "../ui/image";

export const AcceptedPayments = ({
  payments,
  label,
  className,
  labelClassName,
}: {
  payments:
    | ResolvedAssetLink<"WITHOUT_UNRESOLVABLE_LINKS", string>[]
    | undefined;
  label?: string;
  className?: string;
  labelClassName?: string;
}) => {
  return (
    <div className={cn("pt-3 text-center", className)}>
      <span className={labelClassName}>{label}</span>
      <div className="flex max-w-[250px] flex-1-0-0 flex-row flex-wrap gap-3 self-center sm:justify-center md:justify-normal">
        {payments &&
          payments.map(payment => (
            <Image
              width={40}
              height={24}
              loading="lazy"
              key={payment?.sys.id}
              className="flex self-center "
              alt={payment?.fields.title}
              src={payment?.fields.file?.url}
            />
          ))}
      </div>
    </div>
  );
};

const FooterMenuItem = ({
  content,
  label,
  additionalItem,
  className,
}: {
  content: TypeLink<"WITHOUT_UNRESOLVABLE_LINKS", string>[] | undefined;
  label?: string;
  additionalItem?: ReactElement;
  className?: string;
}) => {
  const url = useURL();
  return (
    <>
      <div className="hidden w-1/4 flex-col justify-center gap-4 px-4 md:flex">
        <span className="text-lg font-bold">{label}</span>
        <div className={cn("gep-4 flex flex-col justify-center", className)}>
          {content &&
            content.map(link => (
              <Link
                key={link?.sys.id}
                className="text-base font-normal text-accent-12"
                to={url(link?.fields.link)}
              >
                {link?.fields.title}
              </Link>
            ))}
          {additionalItem}
        </div>
      </div>
      <AccordionItem className="block w-full md:hidden" value={label || ""}>
        <AccordionTrigger
          className={
            "flex w-full items-center justify-between border-b-0 bg-white px-10 py-2 text-left shadow-[inset_0px_1px_2px_0px_#00000024] data-[state=open]:bg-inherit data-[state=open]:shadow-none"
          }
        >
          <span className="text-lg font-medium text-neutral-11">{label}</span>
        </AccordionTrigger>
        <AccordionContent className="[&>div]:p-0">
          <div className="flex flex-col gap-4 bg-white px-6 py-5 shadow-sm">
            {content &&
              content.map(link => (
                <Link
                  key={link?.sys.id}
                  className="text-base font-normal text-accent-12"
                  to={url(link?.fields.link)}
                >
                  {link?.fields.title}
                </Link>
              ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </>
  );
};

export const Footer = ({
  content,
}: {
  content: SerializeFrom<
    Entry<TypeFooterSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
  >;
}) => {
  const url = useURL();
  return (
    <footer className="mx-auto flex w-full flex-col pt-16">
      <div className="w-full py-4 text-center">
        Connect with us
        <div className="flex w-full justify-center gap-3 self-center px-3">
          {content.fields.socialLinks.map(socialLink => (
            <Link key={socialLink.sys.id} to={url(socialLink.fields.link)}>
              <Image
                className="flex self-center "
                alt={socialLink.fields.name}
                width={40}
                height={40}
                loading="lazy"
                //eslint-disable-next-line
                fetchPriority="low"
                src={socialLink.fields.image?.fields.file?.url}
              />
            </Link>
          ))}
        </div>
      </div>
      <div className="flex w-full flex-row flex-wrap items-baseline self-stretch bg-surface-accent-light px-5 pt-5 md:bg-inherit md:px-48 md:py-12">
        <div className="justify-left flex w-full gap-1 self-center bg-white px-7 py-2 pb-2 text-left text-lg font-medium text-neutral-11 md:hidden">
          <CoutrySelector />
        </div>
        <Link
          className="justify-left flex w-full gap-1 self-center bg-white px-7 py-2 pb-2 text-left text-lg font-medium text-neutral-11 shadow-[inset_0px_1px_2px_0px_#00000024] md:hidden"
          to={url("/account")}
        >
          <UserIcon width={24} />
          Account
        </Link>
        <Link
          className="justify-left flex w-full gap-1 self-center bg-white px-7 py-2 pb-2 text-left text-lg font-medium text-neutral-11 shadow-[inset_0px_1px_2px_0px_#00000024] md:hidden"
          to={url(content.fields.storeFinder?.fields.link || "")}
        >
          <BuildingOffice2Icon width={24} />
          {content.fields.storeFinder?.fields.title}
        </Link>
        <Accordion
          type="single"
          collapsible
          defaultValue="details"
          className="block md:flex md:items-baseline"
        >
          <FooterMenuItem
            content={content.fields.customerSupport}
            label="Customer Support"
            additionalItem={<CoutrySelector />}
            className="flex-col-reverse"
          />
          <FooterMenuItem
            content={content.fields.shoppingWithUs}
            label="Shopping with us"
            additionalItem={
              <Link
                className="text-base font-normal text-accent-12"
                to={url(content.fields.storeFinder?.fields.link || "")}
              >
                {content.fields.storeFinder?.fields.title}
              </Link>
            }
          />
          <FooterMenuItem
            content={content.fields.corporate}
            label="Corporate"
          />
          <FooterMenuItem
            content={content.fields.privacyTerms}
            label="Privacy & terms"
            additionalItem={
              <AcceptedPayments
                payments={content.fields.paymentTypes?.fields.collection}
                label="Payment Types"
                className="text-left"
              />
            }
          />
        </Accordion>
      </div>
      <div className="flex w-full flex-1-0-0 flex-col items-center justify-center gap-4 self-stretch bg-surface-accent-light py-4 text-base font-light">
        <AcceptedPayments
          payments={content.fields.paymentTypes?.fields.collection}
          label="Payment Types"
          className="md:hidden [&_div]:justify-center"
        />
        <div className="text-xs text-neutralalpha-11">
          {content.fields.about}
        </div>
        <div className="text-xs text-neutralalpha-11">
          {content.fields.copyright}
        </div>
      </div>
    </footer>
  );
};
export const SimplifiedFooter = ({
  content,
}: {
  content: SerializeFrom<
    Entry<TypeSimplifiedFooterSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
  >;
}) => {
  return (
    <footer className="mx-auto flex w-full flex-col">
      <div className="flex w-full justify-center self-center md:px-3 md:py-5">
        <Accordion
          type="single"
          collapsible
          defaultValue="details"
          className="block w-full max-w-7xl md:flex md:items-baseline"
        >
          <AccordionItem value="need-help" className="w-full">
            <AccordionTrigger className="flex w-full items-center justify-between border-b bg-white px-3 py-4 text-left shadow-sm data-[state=open]:bg-inherit data-[state=open]:shadow-none md:px-7">
              <span className="text-lg font-normal text-neutral-11">
                Need Help?
              </span>
            </AccordionTrigger>
            <AccordionContent className="[&>div]:p-0">
              <div className="bg-white px-6 py-5 shadow-sm">
                <RichTextRender content={content.fields.helpSection} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="flex w-full flex-1-0-0 flex-col items-center justify-center gap-3 self-stretch bg-surface-accent-light py-5 text-base font-light text-text-dark-on-light">
        <div>
          <PhoneIcon width={24} className="inline" /> Order by phone on{" "}
          <span className="font-normal">{content.fields.contact}</span>
        </div>
        <div className="text-xs text-neutralalpha-11">
          {content.fields.copyright}
        </div>
        <AcceptedPayments
          payments={content.fields.paymentTypes?.fields.collection}
          className="[&_div]:justify-center"
        />
      </div>
    </footer>
  );
};
